var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"funders-page"}},[_c('div',{staticClass:"grid-row",attrs:{"id":"funders-page-content"}},[_c('div',{staticClass:"col-11"},[_c('h1',[_vm._v("Funders")]),_c('p',[_vm._v("\n        Europe PMC has "+_vm._s(_vm.FUNDERS.length)+" research funders, across Europe.\n        The Europe PMC funders expect:\n      ")]),_vm._m(0),_c('p',[_vm._v("\n        For further information, please refer to each funder's individual open\n        access policy using the links below.\n      ")]),_vm._l((_vm.FUNDERS),function(funder){return _c('div',{key:funder.name,staticClass:"funders-container"},[_c('div',{staticClass:"funder-image-container"},[_c('a',{staticClass:"image-link",style:({ width: funder.imageWidth + 'px !important' }),attrs:{"href":funder.url,"aria-label":'Link to ' + funder.name + ' website'}},[_c('img',{style:({ width: funder.imageWidth + 'px !important' }),attrs:{"src":_vm.getImageSrc(funder),"alt":funder.name + ' logo'}})])]),_c('ul',[_c('li',[_c('a',{attrs:{"href":`/search?query=${_vm.getArticlesFundedQuery(funder)}`}},[_vm._v("\n              Publications funded\n              "+_vm._s(funder.abbr === 'WHO' ? '(all WHO)' : '')+"\n            ")])]),(funder.iarc)?_c('li',[_c('a',{attrs:{"href":`/search?query=${_vm.getArticlesFundedQuery(
                funder,
                'IARC'
              )}`}},[_vm._v("\n              Publications funded\n              "+_vm._s(funder.abbr === 'WHO' ? '(IARC only)' : '')+"\n            ")])]):_vm._e(),(funder.oaPolicy)?_c('li',[_c('a',{attrs:{"href":funder.oaPolicy,"aria-label":'Link to ' + funder.name + ' open access policy'}},[_vm._v("\n              Open access policy "),_c('i',{staticClass:"fas fa-external-link-alt"})])]):_vm._e(),(funder.oaReq)?_c('li',[_c('a',{attrs:{"href":funder.oaReq,"aria-label":'Link to ' + funder.name + ' open access requirements'}},[_vm._v("\n              Open access requirements\n              "),_c('i',{staticClass:"fas fa-external-link-alt"})])]):_vm._e(),_c('li',[_c('a',{attrs:{"href":_vm._f("getGrantLink")(funder)}},[_vm._v("Grants")])]),_c('li',[_c('a',{attrs:{"href":`/funder-dashboard?funderName=${encodeURIComponent(
                funder.value[0]
              )}`}},[_vm._v("\n              Funder dashboard\n            ")])]),(funder.other)?_c('li',{domProps:{"innerHTML":_vm._s(funder.other)}}):_vm._e()])])}),_c('h2',[_vm._v("Supported by")]),_c('div',[_vm._v("\n        Europe PMC receives in-kind support from European Molecular Biology\n        Laboratory\n      ")]),_vm._m(1)],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("\n          Research outputs arising from research that we fund to be made\n          freely and readily available;\n        ")]),_c('li',[_vm._v("\n          Electronic copies of any biomedical research papers that have been\n          accepted for publication in a peer-reviewed journal, and are\n          supported in whole or in part by funding from any of the Europe PMC\n          Funders, to be made available through PubMed Central (PMC) and\n          Europe PMC, as soon as possible and in any event within six months\n          of the journal publisher's official date of final publication;\n        ")]),_c('li',[_vm._v("\n          Authors and publishers, if an open access fee has been paid, to\n          license research papers such that they may be freely copied and\n          re-used for purposes such as text and data mining, provided that\n          such uses are fully attributed. This is also encouraged where no fee\n          has been paid.\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"embl-container"},[_c('div',{staticClass:"embl-image-container"},[_c('a',{staticClass:"embl-image-link",attrs:{"href":"//www.embl.de/","aria-label":"Link to EMBL website"}},[_c('img',{staticClass:"embl-image",attrs:{"src":require("@/assets/embl.png"),"alt":"EMBL logo"}})])]),_c('ul',{staticClass:"embl-links"},[_c('li',[_c('a',{attrs:{"href":"//www.embl.org/about/info/szilard-library/wp-content/uploads/2020/10/IP-66-EMBL-Open-Access-Policy.pdf","aria-label":"Link to EMBL open access policy"}},[_vm._v("\n              Open access policy "),_c('i',{staticClass:"fas fa-external-link-alt"})])]),_c('li',[_c('a',{attrs:{"href":"//www.embl.org/about/info/szilard-library/open-access/","aria-label":"Link to EMBL open access information"}},[_vm._v("\n              Open access information "),_c('i',{staticClass:"fas fa-external-link-alt"})])]),_c('li',[_c('a',{attrs:{"href":"https://www.ebi.ac.uk/long-term-data-preservation","aria-label":"Link to EMBL long-term data preservation information"}},[_vm._v("\n              Long-term data preservation\n              "),_c('i',{staticClass:"fas fa-external-link-alt"})])])])])
}]

export { render, staticRenderFns }